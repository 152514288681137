<template>
  <div>
    <b-form-group class="border border-info bg-white rounded p-3">
      <FormText
        label="大学:"
        v-model="edit.university"
      />
    </b-form-group>

    <b-form-group class="border border-info bg-white rounded p-3">
      <FormText
        label="学部:"
        v-model="edit.uni_faculty"
      />
    </b-form-group>

    <b-form-group class="border border-info bg-white rounded p-3">
      <FormText
        label="学科:"
        v-model="edit.uni_department"
      />
    </b-form-group>

    <b-form-group class="border border-info bg-white rounded p-3">
      <FormText
        label="キャッチコピー:"
        v-model="edit.slogan"
      />
    </b-form-group>
  </div>
</template>

<script>
// import { saveAs } from 'file-saver'
import FormText from './parts/FormText'

export default {
  name: 'editor',
  props: {
    // msg: String
  },
  data() {
    return {
      snsOptions: [],
      color: {
        background: '',
        text: '',
      },
      fontfamily: '',
      boston: {
        fontfamily: {},
        fontfamily_nameMain: {},
      }
    }
  },
  computed: {
    setting() {
      return this.$store.state.setting
    },
    edit() {
      return this.$store.state.edit
    },
    style() {
      return this.$store.state.style
    },
    option() {
      return this.$store.state.option
    }
  },
  components: {
    FormText,
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
