<template>
  <b-form-group class="border border-info bg-white rounded p-3">
    <FormSelectImage
      label="背景画像: (jpeg,png)"
      :imageOptions="option.backgroundImage"
      :disableDelete="option.disableBackgroundImageDelete"
      :disableUpload="option.disableBackgroundImageUpload"
      v-model="edit.backgroundImage"
    />

    <FormOptionGroup
      title="大きさや位置の変更"
      :visible="true"
      id="backgroundImage-style"
      v-if="option.style.backgroundImage"
    >
      <FormOptionSlider
        label="ズーム"
        step="0.01"
        :min="option.style.backgroundImage.scale.min"
        :max="option.style.backgroundImage.scale.max"
        v-model="style.backgroundImage.scale"
        v-if="option.style.backgroundImage.scale"
      />
      <FormOptionSlider
        label="縦位置"
        step="1"
        :min="option.style.backgroundImage.posY.min"
        :max="option.style.backgroundImage.posY.max"
        v-model="style.backgroundImage.pos.y"
        v-if="option.style.backgroundImage.posY"
      />
      <FormOptionSlider
        label="横位置"
        step="1"
        :min="option.style.backgroundImage.posX.min"
        :max="option.style.backgroundImage.posX.max"
        v-model="style.backgroundImage.pos.x"
        v-if="option.style.backgroundImage.posX"
      />
    </FormOptionGroup>
  </b-form-group>
</template>

<script>
// import { saveAs } from 'file-saver'
import FormSelectImage from './parts/FormSelectImage'
import FormOptionGroup from  './parts/FormOptionGroup'
import FormOptionSlider from  './parts/FormOptionSlider'

export default {
  computed: {
    setting() {
      return this.$store.state.setting
    },
    edit() {
      return this.$store.state.edit
    },
    style() {
      return this.$store.state.style
    },
    option() {
      return this.$store.state.option
    }
  },
  watch:  {
    'edit.backgroundImage': {
      handler () {
        const resizeBackgroundImage = this.resizeBackgroundImage
        let img = new Image()
        img.onload = function() {
          if ( img.width/img.height < 16/9 ) {
            resizeBackgroundImage(1920, 1920 * img.height/img.width)
          } else {
            resizeBackgroundImage(1080 * img.width/img.height, 1080)
          }
        }
        img.src = this.edit.backgroundImage
      },
      deep: false,
      immediate: false
    },
  },
  mounted() {
  },
  methods: {
    resizeBackgroundImage(width, height) {
      this.style.backgroundImage.size.width = width
      this.style.backgroundImage.size.height = height
    },
  },
  components: {
    FormSelectImage,
    FormOptionGroup,
    FormOptionSlider,
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
