<template>
  <div class="border border-info bg-white rounded p-3">
    <b-form-group v-for="(item, n) in edit.sns" :key="item">
      <div class="border-top pt-3" v-if="n != 0"></div>

      <b-row class="align-items-start">
        <b-col>
          <label v-if="setting.appId=='boston'">Media{{ (n+1) }}:</label>
          <label v-if="setting.appId=='eisai'">Media{{ (n+1) }}:</label>
          <label v-else>Media/SNS{{ (n+1) }}:</label>
        </b-col>
        <b-col cols="auto" class="ml-auto">
          <b-button
            size="sm"
            variant="link"
            class="text-danger p-0 border-0"
            @click="item.name = null"
          ><i class="fa-solid fa-trash"></i> 削除</b-button>
        </b-col>
      </b-row>

      <b-row class="align-items-center">
        <b-col class="overflow-auto">
          <b-form-select v-model="item.name">
            <b-form-select-option
              :value="option.value"
              v-for="(option, key) in snsOptions"
              :key="key"
            >{{ option.text }}</b-form-select-option>
          </b-form-select>

          <b-input-group v-if="item.name !== null">
            <b-input-group-text v-if="option.sns[item.name].prepend !== ''">
              {{ option.sns[item.name].prepend }}
            </b-input-group-text>
            <b-form-input
              :id="'sns['+n+']'"
              v-model="item.content"
              placeholder=""
              rows="1"
              v-if="!option.sns[item.name].disableInput"
            ></b-form-input>
          </b-input-group>
        </b-col>

        <b-col cols="auto" class="pl-0">
          <b-button-group vertical>
            <b-button
              size="sm"
              class="py-0"
              variant="outline-info"
              @click="moveSns(n-1, n);"
              v-b-tooltip.hover="'一つ前と入れ替える'"
              v-if="n != 0"
            ><i class="fa-solid fa-caret-up"></i></b-button>
            <b-button
              size="sm"
              class="py-0"
              variant="outline-info"
              @click="moveSns(n, n+1);"
              v-b-tooltip.hover="'一つ後と入れ替える'"
              v-if="n != 4"
            ><i class="fa-solid fa-caret-down"></i></b-button>
          </b-button-group>
        </b-col>

      </b-row>
    </b-form-group>
  </div>
</template>

<script>
// import { saveAs } from 'file-saver'

export default {
  data() {
    return {
      snsOptions: [],
    }
  },
  computed: {
    setting() {
      return this.$store.state.setting
    },
    edit() {
      return this.$store.state.edit
    },
    style() {
      return this.$store.state.style
    },
    option() {
      return this.$store.state.option
    }
  },
  mounted() {
    let options = []
    options.push({
      value: null,
      text: 'なし',
    })
    for (let key in this.option.sns) {
      if (this.option.sns[key]) {
        options.push({
          value: key,
          text: this.option.sns[key].text,
        })
      }
    }
    this.snsOptions = options
  },
  methods: {
    moveSns(index1, index2) {
      let tmp1 = this.edit.sns[index1]
      let tmp2 = this.edit.sns[index2]
      this.edit.sns.splice(index1, 1, tmp2)
      this.edit.sns.splice(index2, 1, tmp1)
    }
  },
  components: {
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
