<template>
  <div>
    <div>
      <b-button
        size="sm"
        variant="link"
        v-b-toggle="id"
      ><i class="fa-solid fa-cog"></i> {{ title }}</b-button>
    </div>
    <b-collapse :visible="visible" :id="id">
      <b-form-group class="rounded mb-0">
        <slot></slot>
      </b-form-group>
    </b-collapse>
  </div>
</template>

<script>
export default {
  props: {
    id: String,
    title: String,
    visible: Boolean
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
