<template>
  <div>
    <b-row class="align-items-start">
      <b-col>
        <label>{{ label }}</label>
      </b-col>
      <b-col cols="auto" class="ml-auto">
      </b-col>
    </b-row>
    <b-tabs small pills card end>
      <b-tab title="簡単" active>
        <ColorSwatch v-model="value" :palette="swatch" v-if="swatch.length"/>
        <ColorSwatch v-model="value" v-if="appendDefaultSwatch"/>
        <ColorSwatch v-model="value" v-if="!swatch.length"/>
      </b-tab>
      <b-tab title="上級者向け" v-if="disableAdvancePicker"><ColorPicker v-model="value" /></b-tab>
    </b-tabs>
  </div>
</template>

<script>
import VueColor from 'vue-color'

export default {
  props: {
    value: {},
    label: String,
    swatch: Array,
    appendDefaultSwatch: Boolean,
    disableAdvancePicker: Boolean
  },
  watch: {
    value() {
      this.handleChange(this.value)
    }
  },

  methods: {
    handleChange (value) {
      this.$emit('input', value)
      this.$emit('change', value)
    },
  },
  components: {
    ColorPicker: VueColor.Chrome,
    ColorSwatch: VueColor.Compact,
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.vc-chrome, .vc-compact {
  margin: 0 auto;
  box-shadow: none;
}
</style>
