<template>
  <div class="editor p-3">
    <div class="canvasWrap" style="background: url(img/toumei_bg.jpg); line-height: 0;">
      <canvas id="canvas" width="1920" height="1080"></canvas>
    </div>
  </div>
</template>

<script>
import QRCode from 'qrcode'
import isBase64 from 'is-base64'

export default {
  props: {
    // msg: String
  },
  data() {
    return {
      canvas: null,
      ctx: null,
      canvasRenderImageFlag: false,
      canvasRenderTextFlag: false,
    }
  },
  computed: {
    setting() {
      return this.$store.state.setting;
    },
    edit() {
      return this.$store.state.edit;
    },
    style() {
      return this.$store.state.style;
    },
    option() {
      return this.$store.state.option;
    }
  },
  mounted() {
    if (this.setting.useFontplus) {
      console.log('FONTPLUS.reload')
      FONTPLUS.reload(false) // eslint-disable-line
    }
    this.init();
    this.render();
  },
  watch:  {
    'edit.department': function() {
      if (this.setting.useFontplus) {
        this.render(this.setting.renderDelay, true)
      }
    },
    'edit.title': function() {
      if (this.setting.useFontplus) {
        this.render(this.setting.renderDelay, true)
      }
    },
    'edit.nameMain': function() {
      if (this.setting.useFontplus) {
        this.render(this.setting.renderDelay, true)
      }
    },
    'edit.nameSub': function() {
      if (this.setting.useFontplus) {
        this.render(this.setting.renderDelay, true)
      }
    },
    'edit.mail': function() {
      if (this.setting.useFontplus) {
        this.render(this.setting.renderDelay, true)
      }
    },
    edit: {
      handler () {
        this.render()
        console.log(this.edit)
      },
      deep: true,
      immediate: false
    },
    style: {
      handler () {
        this.render()
        console.log(this.style)
      },
      deep: true,
      immediate: false
    },
  },
  methods: {
    init() {
      this.canvas = document.getElementById('canvas')
      this.ctx = this.canvas.getContext('2d')
    },
    render(delay = 50, fontLoad = false) {
      if (fontLoad) {
        console.log('FONTPLUS.reload')
        FONTPLUS.reload(false) // eslint-disable-line
      }
      let timer
      clearTimeout( timer )
      timer = setTimeout(this.renderExec, delay )
    },
    renderExec() {
      console.log("render start")
      const setting = this.setting
      const edit = this.edit
      const style = this.style
      const ctx = this.ctx
      const renderExecText = this.renderExecText
      const addImage = this.addImage
      ctx.clearRect(0, 0, 1920, 1080)
      // ctx.fillStyle = '#ffffff'
      // ctx.fillRect(0, 0, 1920, 1080)

      // 背景画像
      addImage({
        img: edit.backgroundImage,
        style: style.backgroundImage,
        callback() {
          // 背景色
          ctx.fillStyle = style.backgroundColor.color
          ctx.fillRect(style.backgroundColor.pos.x, style.backgroundColor.pos.y, style.backgroundColor.size.width, style.backgroundColor.size.height)

          addImage({
            img: edit.corpLogo,
            style: style.corpLogo
          })
          if (setting.appId == 'mynavi') {
            addImage({
              img: edit.poweredBy,
              style: style.poweredBy
            })
            addImage({
              img: edit.deco,
              style: style.deco,
              callback() {
                renderExecText()
              }
            })
          } else {
            renderExecText()
          }
        }
      })
    },
    renderExecText() {
      const edit = this.edit
      const style = this.style
      const option = this.option
      const addText = this.addText
      const addMailcode = this.addMailcode
      const addSnscode = this.addSnscode
      const _isString = this.$_.isString

      if (edit.department !== null && edit.department !== undefined) {
        addText({
          text: edit.department,
          style: style.department
        })
      }
      if (edit.title !== null && edit.title !== undefined) {
        addText({
          text: edit.title,
          style: style.title
        })
      }
      addText({
        text: edit.nameMain,
        style: style.nameMain
      })
      addText({
        text: edit.nameSub,
        style: style.nameSub
      })
      if (edit.university !== null && edit.university !== undefined) {
        addText({
          text: edit.university,
          style: style.university
        })
      }
      if (edit.uni_faculty !== null && edit.uni_faculty !== undefined) {
        addText({
          text: edit.uni_faculty,
          style: style.uni_faculty
        })
      }
      if (edit.uni_department !== null && edit.uni_department !== undefined) {
        addText({
          text: edit.uni_department,
          style: style.uni_department
        })
      }
      if (edit.motto !== null && edit.motto !== undefined) {
        addText({
          text: edit.motto,
          style: style.motto
        })
      }
      if (edit.slogan !== null && edit.slogan !== undefined) {
        addText({
          text: edit.slogan,
          style: style.slogan
        })
      }
      if (edit.mail !== null && edit.mail !== undefined) {
        addMailcode({
          text: edit.mail,
          style: style.mail
        })
      }

      if ( edit.sns.length > 0 ) {
        for (let i = 0; i < edit.sns.length; i++) {
          if ( _isString(edit.sns[i].content) && edit.sns[i].name!==null ) {
            addSnscode({
              url: option.sns[edit.sns[i].name].prepend+edit.sns[i].content,
              style: style.sns[i],
              option: option.sns[edit.sns[i].name],
            })
          }
        }
      }
    },
    addText(params) {
      if ( !this.$_.isString(params.text) && params.text=="" ) {
        if(params.callback !== undefined) params.callback()
        return
      }
      const style = params.style
      let column = ['']
      let lineHeight = 1.1618  // 行の高さ (フォントサイズに対する倍率)
      let line = 0
      this.ctx.fillStyle = style.color
      this.ctx.font = style.font.weight+' '+style.font.size+'px '+style.font.family
      this.ctx.textAlign = style.textAlign || "left"
      this.ctx.textBaseline = "top"

      for (let i = 0; i < params.text.length; i++) {
          let char = params.text.charAt(i)

          if (char == '\n' || this.ctx.measureText(column[line] + char).width > style.width) {
              line++
              column[line] = ''
          }
          column[line] += char
      }

      for (let j = 0; j < column.length; j++) {
        let addY = 0
        let addX = 0
        if ( j ) addY += style.font.size * lineHeight * j 
        if ( j ) addX = -1 * style.font.size * 0.25
        this.ctx.fillText(column[j], Number(style.pos.x)+addX, Number(style.pos.y)+addY)
      }

      if(params.callback !== undefined) params.callback()
    },
    addMailcode(params) {
      let style = params.style
      let ctx = this.ctx
      QRCode.toDataURL(params.text, function (err, data) {
        var img = new Image()
        img.src = data
        img.onload = function() {
          ctx.drawImage(img, style.qr.pos.x, style.qr.pos.y, style.qr.size.width, style.qr.size.width)
        }
      })
      this.addText({
        text: params.text,
        style: {
          font: {
            family: style.text.font.family,
            size: style.text.font.size,
            weight: style.text.font.weight
          },
          color: style.text.color,
          width: style.text.width,
          pos: {
            x: Number(style.qr.pos.x) + 10,
            y: Number(style.qr.pos.y) + style.qr.size.width +10
          }
        }
      })
    },
    addSnscode(params) {
      const style = params.style
      const option = params.option
      const ctx = this.ctx

      QRCode.toDataURL(params.url, function (err, data) {
        let qr = new Image()
        qr.onload = function() {
          ctx.drawImage(qr, style.qr.pos.x, style.qr.pos.y, style.qr.size.width, style.qr.size.width)

          if (option.icon != '') {
            let icon = new Image()
            icon.onload = function() {
              let iconBgWidth = style.qr.size.width*0.22
              let iconWidth = iconBgWidth * 0.9 * option.widthcorrection
              let iconHeight = iconWidth * (icon.height / icon.width)
              ctx.fillStyle = '#ffffff'
              ctx.fillRect(
                style.qr.pos.x + style.qr.size.width/2 - iconBgWidth/2, // position x
                style.qr.pos.y + style.qr.size.width/2 - iconBgWidth/2, // position y
                iconBgWidth, // width
                iconBgWidth // height
              )
              ctx.drawImage(
                icon,
                style.qr.pos.x + style.qr.size.width/2 - iconWidth/2,
                style.qr.pos.y + style.qr.size.width/2 - iconHeight/2,
                iconWidth,
                iconHeight
              )
            }

            icon.src = option.icon
          }
        }
        qr.src = data
      })

      if(this.option.showSnsLabel) {
        var padding = 15
        this.addRoundBox({
          style: {
            x: Number(style.qr.pos.x) - style.text.width - padding*2 -4,
            y: Number(style.qr.pos.y) + 0,
            w: style.text.width + padding,
            h: style.text.font.size*1.1618 *2 + padding*2,
            r: 8,
          }
        })
        this.addText({
          text: params.option.label,
          style: {
            font: {
              family: style.text.font.family,
              size: style.text.font.size,
              weight: style.text.font.weight
            },
            textAlign: "center",
            color: style.text.color,
            width: style.text.width,
            pos: {
              x: Number(style.qr.pos.x) - style.text.width/2 - 30,
              y: Number(style.qr.pos.y) + padding
            }
          }
      })
      }
    },
    addImage(params) {
      if ( !isBase64(params.img, {allowMime: true}) || params.img=='' ) {
        if(params.callback !== undefined) params.callback()
        return
      }
      const style = params.style
      const ctx = this.ctx

      let height = 0
      let scale = style.scale || 1
      let crop = {}

      let img = new Image()
      img.onload = function() {
        if (style.size.height == undefined) {
          height = style.size.width * (img.height / img.width)
        } else {
          height = style.size.height
        }

        if (style.crop == undefined) {
          crop = {
            offset: {
              x: 0, y: 0,
            },
            size: {
              width: img.width, height: img.height
            }
          }
        } else {
          crop = style.crop
        }

        ctx.drawImage(
          img,
          crop.offset.x, crop.offset.y, crop.size.width, crop.size.height, // 切り抜き位置サイズ
          style.pos.x, style.pos.y, style.size.width*scale, height*scale, // 表示位置サイズ
        )

        if(params.callback !== undefined) params.callback()
      }
      img.src = params.img
    },
    addRoundBox(params) {
      const style = params.style
      const ctx = this.ctx
      const x = style.x, y = style.y, w = style.w, h = style.h, r = style.r
      ctx.fillStyle = this.style.backgroundColor.color;

      ctx.beginPath();
      ctx.moveTo(x + r, y);
      ctx.lineTo(x + w - r, y);
      ctx.arc(x + w - r, y + r, r, Math.PI * (3/2), 0, false);
      ctx.lineTo(x + w, y + h - r);
      ctx.arc(x + w - r, y + h - r, r, 0, Math.PI * (1/2), false);
      ctx.lineTo(x + r, y + h);       
      ctx.arc(x + r, y + h - r, r, Math.PI * (1/2), Math.PI, false);
      ctx.lineTo(x, y + r);
      ctx.arc(x + r, y + r, r, Math.PI, Math.PI * (3/2), false);
      ctx.closePath();
      ctx.fill();
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.editor {
  canvas {
    width: 100%;
    border: 4px solid #000;
  }
}
</style>
