import { render, staticRenderFns } from "./FormTextColor.vue?vue&type=template&id=31847735&scoped=true"
import script from "./FormTextColor.vue?vue&type=script&lang=js"
export * from "./FormTextColor.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31847735",
  null
  
)

export default component.exports