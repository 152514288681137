<template>
  <div>
    <b-row class="align-items-start">
      <b-col>
        <label>{{ label }}</label>
      </b-col>
      <b-col cols="auto" class="ml-auto">
      </b-col>
    </b-row>
    <b-form-select v-model="value">
      <b-form-select-option
        :value="opt.font"
        v-for="(opt, key) in options"
        :key="key"
      >{{ opt.text }}</b-form-select-option>
    </b-form-select>
  </div>
</template>

<script>
export default {
  props: {
    value: {},
    label: String,
    options: Array,
  },

  watch: {
    value() {
      this.handleChange(this.value)
    }
  },
  methods: {
    handleChange (value) {
      this.$emit('input', value)
      this.$emit('change', value)
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
