<template>
  <div>
    <b-row class="align-items-start">
      <b-col>
        <label>{{ label }}</label>
      </b-col>
      <b-col cols="auto" class="ml-auto">
      </b-col>
    </b-row>
    <b-input-group>
      <b-input-group-text class="w-25">英数字フォント</b-input-group-text>
      <b-form-select v-model="value.en">
        <b-form-select-option
          :value="opt.font"
          v-for="(opt, key) in options_en"
          :key="key"
        >{{ opt.text }}</b-form-select-option>
      </b-form-select>
    </b-input-group>
    <b-input-group>
      <b-input-group-text class="w-25">日本語フォント</b-input-group-text>
      <b-form-select v-model="value.jp">
        <b-form-select-option
          :value="opt.font"
          v-for="(opt, key) in options_jp"
          :key="key"
        >{{ opt.text }}</b-form-select-option>
      </b-form-select>
    </b-input-group>
    <b-input-group>
      <b-input-group-addon class="w-25"></b-input-group-addon>
      <b-input-group-addon>
        <b-form-checkbox
          v-model="value.weight"
          value="bold"
          unchecked-value="normal"
        >
          太字にする
        </b-form-checkbox>
      </b-input-group-addon>
    </b-input-group>
  </div>
</template>

<script>
export default {
  props: {
    value: {},
    label: String,
    options_en: Array,
    options_jp: Array,
  },

  watch: {
    value() {
      this.handleChange(this.value)
    }
  },
  methods: {
    handleChange (value) {
      this.$emit('input', value)
      this.$emit('change', value)
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
