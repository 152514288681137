<template>
  <b-form-group class="border border-info bg-white rounded p-3">
    <FormSelectColor
      label="文字色:"
      :swatch="option.color.text.swatch"
      :appendDefaultSwatch="option.color.text.defaultSwatch"
      :disableAdvancePicker="option.color.text.advance"
      v-model="value"
    />
  </b-form-group>
</template>

<script>
// import { saveAs } from 'file-saver'
import FormSelectColor from './parts/FormSelectColor'

export default {
  name: 'editor',
  props: {
    // msg: String
  },
  data() {
    return {
      value: ''
    }
  },
  computed: {
    setting() {
      return this.$store.state.setting
    },
    edit() {
      return this.$store.state.edit
    },
    style() {
      return this.$store.state.style
    },
    option() {
      return this.$store.state.option
    }
  },
  watch:  {
    'value': {
      handler () {
        let color = 'rgba(' + this.value.rgba.r + ', ' + this.value.rgba.g + ', ' + this.value.rgba.b + ', ' + this.value.rgba.a + ')'
        this.style.department.color = color
        this.style.title.color = color
        this.style.nameMain.color = color
        this.style.nameSub.color = color
        this.style.mail.text.color = color
        for (let i = 0; i < this.style.sns.length; i++) {
          this.style.sns[i].text.color = color
        }
      },
      deep: false,
      immediate: false
    },
  },
  mounted() {
    this.value = this.style.department.color
  },
  components: {
    FormSelectColor,
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
