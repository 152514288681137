<template>
  <div>
    <b-row class="align-items-start">
      <b-col>
        <label>{{ label }}</label>
      </b-col>
      <b-col cols="auto" class="ml-auto">
        <b-button
          size="sm"
          variant="link"
          class="text-danger p-0 border-0"
          v-if="!disableDelete"
          @click="handleChange('')"
        ><i class="fa-solid fa-trash"></i> 削除</b-button>
      </b-col>
    </b-row>
    <div class="row flex-wrap py-2" style="margin: -0.25rem;">
      <div class="col-xl-4 col-lg-6 col-md-4 col-6 p-1" v-if="!disableUpload">
        <div
          class="img-thumbnail position-relative"
          :class="{'bg-primary': (value === image && image !== '')}"
          style="cursor: pointer;"
          @click="function() {if(image !== '') { handleChange(image) } else { $refs.image.click() } }"
        >
          <div class="embed-responsive embed-responsive-16by9">
            <img class="embed-responsive-item bg-white" style="width: auto; height: auto; max-width: 100%; max-height: 100%;" :src="image" alt="" v-if="image">
            <img class="embed-responsive-item" src="https://via.placeholder.com/1920x1080?text=Upload" alt="" v-if="!image">
          </div>
          <b-button class="position-absolute m-1" style="right: 0; bottom: 0;" size="sm" variant="light" @click.stop="$refs.image.click()">ファイル選択</b-button>
        </div>
        <input
          class="d-none"
          ref="image"
          type="file"
          accept=".jpg, .png, .gif"
          @change="uploadImage"
        >
      </div>
      <div class="col-xl-4 col-lg-6 col-md-4 col-6 p-1" v-for="(img, key) in imageOptions" :key="key">
        <div :class="{'thumbnail-active': (value === img)}">
          <img class="w-100 img-thumbnail" :src="img" alt="" style="cursor: pointer;" @click="handleChange(img)" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {},
    label: String,
    imageOptions: Array,
    disableDelete: Boolean,
    disableUpload: Boolean
  },
  data() {
    return {
      image: ''
    }
  },

  methods: {
    handleChange (image) {
      this.$emit('input', image)
      this.$emit('change', image)
    },
    uploadImage(e) {
      const images = e.target.files || e.dataTransfer.files
      const handleChange = this.handleChange
      this.getBase64(images[0])
        .then(image => this.image = image)
        .then(image => handleChange(image))
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '@/assets/scss/custom.scss';

.thumbnail-active {
  position: relative;
  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    border: 5px solid $primary;
  }
}
</style>
