<template>
  <div>
    <b-form-group class="border border-info bg-white rounded p-3">
      <FormText
        label="趣味｜好きなもの｜座右の銘｜ポリシー:"
        v-model="edit.motto"
      />

      <FormOptionGroup
        title="大きさや位置の変更"
        :visible="false"
        id="corpLogo-style"
        v-if="option.style.motto"
      >
        <FormOptionSlider
          label="大きさ"
          step="1"
          :min="option.style.motto.fontSize.min"
          :max="option.style.motto.fontSize.max"
          v-model="style.motto.font.size"
          v-if="option.style.motto.fontSize"
        />
        <FormOptionSlider
          label="横位置"
          step="1"
          :min="option.style.motto.posX.min"
          :max="option.style.motto.posX.max"
          v-model="style.motto.pos.x"
          v-if="option.style.motto.posX"
        />
        <FormOptionSlider
          label="縦位置"
          step="1"
          :min="option.style.motto.posY.min"
          :max="option.style.motto.posY.max"
          v-model="style.motto.pos.y"
          v-if="option.style.motto.posY"
        />
      </FormOptionGroup>
    </b-form-group>

    <b-form-group class="border border-info bg-white rounded p-3">
      <FormSelectImage
        label="吹き出し: (jpeg,png)"
        :imageOptions="option.deco"
        :disableDelete="false"
        :disableUpload="false"
        v-model="edit.deco"
      />
      ▶ <a href="https://fukidesign.com/" target="_blank">おすすめ吹き出し画像素材サイト</a>

      <FormOptionGroup
        title="大きさや位置の変更"
        :visible="false"
        id="deco-style"
        v-if="option.style.deco"
      >
        <FormOptionSlider
          label="大きさ"
          step="1"
          :min="option.style.deco.sizeWidth.min"
          :max="option.style.deco.sizeWidth.max"
          v-model="style.deco.size.width"
          v-if="option.style.deco.sizeWidth"
        />
        <FormOptionSlider
          label="横位置"
          step="1"
          :min="option.style.deco.posX.min"
          :max="option.style.deco.posX.max"
          v-model="style.deco.pos.x"
          v-if="option.style.deco.posX"
        />
        <FormOptionSlider
          label="縦位置"
          step="1"
          :min="option.style.deco.posY.min"
          :max="option.style.deco.posY.max"
          v-model="style.deco.pos.y"
          v-if="option.style.deco.posY"
        />
      </FormOptionGroup>
    </b-form-group>
  </div>
</template>

<script>
// import { saveAs } from 'file-saver'
import FormSelectImage from './parts/FormSelectImage'
import FormText from './parts/FormText'
import FormOptionGroup from  './parts/FormOptionGroup'
import FormOptionSlider from  './parts/FormOptionSlider'

export default {
  computed: {
    setting() {
      return this.$store.state.setting
    },
    edit() {
      return this.$store.state.edit
    },
    style() {
      return this.$store.state.style
    },
    option() {
      return this.$store.state.option
    }
  },
  components: {
    FormSelectImage,
    FormText,
    FormOptionGroup,
    FormOptionSlider,
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
