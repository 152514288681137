<template>
  <div>
    <b-input-group>
      <b-input-group-text class="w-25">
        <small>{{ label }}</small>
      </b-input-group-text>
      <b-input-group-prepend class="w-25">
        <b-form-input
          v-model="value"
          type="number"
          :step="step"
          :min="min"
          :max="max"
        ></b-form-input>
      </b-input-group-prepend>
      <b-form-input
        v-model="value"
        type="range"
        :step="step"
        :min="min"
        :max="max"
      ></b-form-input>
    </b-input-group>
  </div>
</template>

<script>
export default {
  props: {
    value: {},
    label: String,
    step: Number,
    min: Number,
    max: Number,
  },

  watch: {
    value() {
      this.handleChange(this.value)
    }
  },
  methods: {
    handleChange (value) {
      this.$emit('input', value)
      this.$emit('change', value)
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
