<template>
  <b-form-group class="border border-info bg-white rounded p-3">
    <FormSelectColor
      label="背景色:"
      :swatch="option.color.background.swatch"
      :appendDefaultSwatch="option.color.background.defaultSwatch"
      :disableAdvancePicker="option.color.background.advance"
      v-model="value"
    />

    <FormOptionGroup
      title="大きさや位置の変更"
      :visible="true"
      id="backgroundColor-style"
      v-if="option.style.backgroundColor"
    >
      <FormOptionSlider
        label="幅"
        step="1"
        :min="option.style.backgroundColor.width.min"
        :max="option.style.backgroundColor.width.max"
        v-model="style.backgroundColor.size.width"
        v-if="option.style.backgroundColor.width"
      />
      <FormOptionSlider
        label="高さ"
        step="1"
        :min="option.style.backgroundColor.height.min"
        :max="option.style.backgroundColor.height.max"
        v-model="style.backgroundColor.size.height"
        v-if="option.style.backgroundColor.height"
      />
      <FormOptionSlider
        label="縦位置"
        step="1"
        :min="option.style.backgroundColor.posY.min"
        :max="option.style.backgroundColor.posY.max"
        v-model="style.backgroundColor.pos.y"
        v-if="option.style.backgroundColor.posY"
      />
      <FormOptionSlider
        label="横位置"
        step="1"
        :min="option.style.backgroundColor.posX.min"
        :max="option.style.backgroundColor.posX.max"
        v-model="style.backgroundColor.pos.x"
        v-if="option.style.backgroundColor.posX"
      />
    </FormOptionGroup>
  </b-form-group>
</template>

<script>
// import { saveAs } from 'file-saver'
import FormSelectColor from './parts/FormSelectColor'
import FormOptionGroup from  './parts/FormOptionGroup'
import FormOptionSlider from  './parts/FormOptionSlider'

export default {
  data() {
    return {
      value: '',
    }
  },
  computed: {
    setting() {
      return this.$store.state.setting
    },
    edit() {
      return this.$store.state.edit
    },
    style() {
      return this.$store.state.style
    },
    option() {
      return this.$store.state.option
    }
  },
  watch:  {
    'value': {
      handler () {
        let color = 'rgba(' + this.value.rgba.r + ', ' + this.value.rgba.g + ', ' + this.value.rgba.b + ', ' + this.value.rgba.a + ')'
        this.style.backgroundColor.color = color
      },
      deep: false,
      immediate: false
    },
  },
  mounted() {
    this.value = this.style.backgroundColor.color
  },
  components: {
    FormSelectColor,
    FormOptionGroup,
    FormOptionSlider,
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
