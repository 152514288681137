<template>
  <div>
    <b-row class="align-items-start">
      <b-col>
        <label>{{ label }}</label>
      </b-col>
      <b-col cols="auto" class="ml-auto">
        <b-button
          size="sm"
          variant="link"
          class="text-danger p-0 border-0"
          @click="value = ''"
        ><i class="fa-solid fa-trash"></i> 削除</b-button>
      </b-col>
    </b-row>
    <b-form-textarea
      v-model="value"
      placeholder=""
      rows="1"
    ></b-form-textarea>
  </div>
</template>

<script>
export default {
  props: {
    value: {},
    label: String,
    options: Array,
  },

  watch: {
    value() {
      this.handleChange(this.value)
    }
  },
  methods: {
    handleChange (value) {
      this.$emit('input', value)
      this.$emit('change', value)
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
