<template>
  <b-form-group class="border border-info bg-white rounded p-3">
    <FormSelectImage
      label="ロゴ: (jpeg,png)"
      :imageOptions="option.corpLogo"
      :disableDelete="option.disableCorpLogoDelete"
      :disableUpload="option.disableCorpLogoUpload"
      v-model="edit.corpLogo"
    />

    <FormOptionGroup
      title="大きさや位置の変更"
      :visible="true"
      id="corpLogo-style"
      v-if="option.style.corpLogo"
    >
      <FormOptionSlider
        label="大きさ"
        step="0.01"
        :min="option.style.corpLogo.sizeWidth.min"
        :max="option.style.corpLogo.sizeWidth.max"
        v-model="style.corpLogo.size.width"
        v-if="option.style.corpLogo.sizeWidth"
      />
      <FormOptionSlider
        label="縦位置"
        step="1"
        :min="option.style.corpLogo.posY.min"
        :max="option.style.corpLogo.posY.max"
        v-model="style.corpLogo.pos.y"
        v-if="option.style.corpLogo.posY"
      />
      <FormOptionSlider
        label="横位置"
        step="1"
        :min="option.style.corpLogo.posX.min"
        :max="option.style.corpLogo.posX.max"
        v-model="style.corpLogo.pos.x"
        v-if="option.style.corpLogo.posX"
      />
    </FormOptionGroup>
  </b-form-group>
</template>

<script>
// import { saveAs } from 'file-saver'
import FormSelectImage from './parts/FormSelectImage'
import FormOptionGroup from  './parts/FormOptionGroup'
import FormOptionSlider from  './parts/FormOptionSlider'

export default {
  computed: {
    setting() {
      return this.$store.state.setting
    },
    edit() {
      return this.$store.state.edit
    },
    style() {
      return this.$store.state.style
    },
    option() {
      return this.$store.state.option
    }
  },
  components: {
    FormSelectImage,
    FormOptionGroup,
    FormOptionSlider,
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
