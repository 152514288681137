<template>
  <b-form-group class="border border-info bg-white rounded p-3">
    <FormText
      label="部署名:"
      v-model="edit.department"
    />

    <FormOptionGroup
      title="大きさや位置の変更"
      :visible="false"
      id="department-style"
      v-if="option.style.department"
    >
      <FormOptionSlider
        label="大きさ"
        step="1"
        :min="option.style.department.fontSize.min"
        :max="option.style.department.fontSize.max"
        v-model="style.department.font.size"
        v-if="option.style.department.fontSize"
      />
      <FormOptionSlider
        label="縦位置"
        step="1"
        :min="option.style.department.posY.min"
        :max="option.style.department.posY.max"
        v-model="style.department.pos.y"
        v-if="option.style.department.posY"
      />
      <FormOptionSlider
        label="横位置"
        step="1"
        :min="option.style.department.posX.min"
        :max="option.style.department.posX.max"
        v-model="style.department.pos.x"
        v-if="option.style.department.posX"
      />
    </FormOptionGroup>
  </b-form-group>
</template>

<script>
// import { saveAs } from 'file-saver'
import FormText from './parts/FormText'
import FormOptionGroup from  './parts/FormOptionGroup'
import FormOptionSlider from  './parts/FormOptionSlider'

export default {
  computed: {
    setting() {
      return this.$store.state.setting
    },
    edit() {
      return this.$store.state.edit
    },
    style() {
      return this.$store.state.style
    },
    option() {
      return this.$store.state.option
    }
  },
  components: {
    FormText,
    FormOptionGroup,
    FormOptionSlider,
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
