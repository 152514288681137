<template>
  <div>
    <b-form-group class="border border-info bg-white rounded p-3">
      <FormSelectFont_boston
        label="フォント(名前以外):"
        :options_en="option.fontfamily_en"
        :options_jp="option.fontfamily_jp"
        v-model="fontfamily"
      />
    </b-form-group>

    <b-form-group class="border border-info bg-white rounded p-3">
      <FormSelectFont_boston
        label="フォント(名前部分):"
        :options_en="option.fontfamily_en"
        :options_jp="option.fontfamily_jp"
        v-model="fontfamily_nameMain"
      />
    </b-form-group>
  </div>
</template>

<script>
// import { saveAs } from 'file-saver'
import FormSelectFont_boston from './parts/FormSelectFont_boston'

export default {
  name: 'editor',
  props: {
    // msg: String
  },
  data() {
    return {
      fontfamily: {},
      fontfamily_nameMain: {},
    }
  },
  computed: {
    setting() {
      return this.$store.state.setting
    },
    edit() {
      return this.$store.state.edit
    },
    style() {
      return this.$store.state.style
    },
    option() {
      return this.$store.state.option
    }
  },
  watch:  {
    'fontfamily': {
      handler () {
        let fontfamily = this.fontfamily.en + ', ' + this.fontfamily.jp
        this.style.department.font.family = fontfamily
        this.style.title.font.family = fontfamily
        this.style.nameSub.font.family = fontfamily
        this.style.mail.font.family = fontfamily

        let fontweight = this.fontfamily.weight
        this.style.department.font.weight = fontweight
        this.style.title.font.weight = fontweight
        this.style.nameSub.font.weight = fontweight
        this.style.mail.font.weight = fontweight
      },
      deep: true,
      immediate: false
    },
    'fontfamily_nameMain': {
      handler () {
        let fontfamily = this.fontfamily_nameMain.en + ', ' + this.fontfamily_nameMain.jp
        this.style.nameMain.font.family = fontfamily

        let fontweight = this.fontfamily_nameMain.weight
        this.style.nameMain.font.weight = fontweight
      },
      deep: true,
      immediate: false
    },
  },
  mounted() {
    this.fontfamily = {
      en: this.option.fontfamily_en[1].font,
      jp: this.option.fontfamily_jp[0].font,
      weight: 'normal'
    }
    this.fontfamily_nameMain = {
      en: this.option.fontfamily_en[2].font,
      jp: this.option.fontfamily_jp[2].font,
      weight: 'bold'
    }
  },
  components: {
    FormSelectFont_boston,
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
