<template>
  <b-form-group class="border border-info bg-white rounded p-3">
    <FormSelect
      label="フォント:"
      :options="option.fontfamily"
      v-model="fontfamily"
      v-if="setting.appId !== 'boston'"
    />
  </b-form-group>
</template>

<script>
// import { saveAs } from 'file-saver'
import FormSelect from './parts/FormSelect'

export default {
  name: 'editor',
  props: {
    // msg: String
  },
  data() {
    return {
      fontfamily: '',
    }
  },
  computed: {
    setting() {
      return this.$store.state.setting
    },
    edit() {
      return this.$store.state.edit
    },
    style() {
      return this.$store.state.style
    },
    option() {
      return this.$store.state.option
    }
  },
  watch:  {
    'fontfamily': {
      handler () {
        let fontfamily = this.fontfamily
        this.style.department.font.family = fontfamily
        this.style.title.font.family = fontfamily
        this.style.nameMain.font.family = fontfamily
        this.style.nameSub.font.family = fontfamily
        // this.style.mail.text.font.family = fontfamily
        for (let i = 0; i < this.style.sns.length; i++) {
          // this.style.sns[i].text.font.family = fontfamily
        }
      },
      deep: false,
      immediate: false
    },
  },
  components: {
    FormSelect,
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
