import { render, staticRenderFns } from "./FormSns.vue?vue&type=template&id=4780dc84&scoped=true"
import script from "./FormSns.vue?vue&type=script&lang=js"
export * from "./FormSns.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4780dc84",
  null
  
)

export default component.exports